<template>
  <el-form
    size="small"
    ref="editForm"
    label-position="top"
    :model="scale"
    :rules="rules"
  >
    <el-form-item label="Единица измерения" prop="unit">
      <el-input v-model="scale.unit" :disabled="disabled">
        <template slot="prepend">Единица измерения</template>
      </el-input>
    </el-form-item>


<!--    <div>-->
<!--      <el-checkbox-->
<!--        v-model="scale.fixed_pay"-->
<!--        border-->
<!--      >-->
<!--        Использовать утверждённую шкалу для финансовых целей-->
<!--      </el-checkbox>-->
<!--    </div>-->
    <div style="margin-top: 10px">
      <el-checkbox
        v-model="scale.auto_bonus"
        border
      >
        Использовать пропорциональный расчет
      </el-checkbox>
    </div>

    <el-form-item label="Целевые значения">
      <el-row type="flex" justify="space-between">
        <el-col :span="7">
          <el-form-item prop="target_a">
            <el-input type="number" v-model="scale.target_a" :disabled="disabled || scale.fixed_pay">
              <template slot="prepend">Min</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="target_b">
            <el-input type="number" v-model="scale.target_b" :disabled="disabled">
              <template slot="prepend">Target</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="target_c">
            <el-input type="number" v-model="scale.target_c" :disabled="disabled || scale.fixed_pay">
              <template slot="prepend">Max</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item
      label="Выплаты за достижения, %"
    >
      <el-row type="flex" justify="space-between">
        <el-col :span="7">
          <el-form-item prop="pay_a">
            <el-input type="number" v-model="scale.pay_a" :disabled="disabled || scale.fixed_pay">
              <template slot="prepend">Min</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="pay_b">
            <el-input type="number" v-model="scale.pay_b" :disabled="disabled || scale.fixed_pay">
              <template slot="prepend">Target</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="pay_c">
            <el-input type="number" v-model="scale.pay_c" :disabled="disabled || scale.fixed_pay">
              <template slot="prepend">Max</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item
      label="Факт"
    >
      <el-input type="number" v-model="scale.fact" :disabled="disabled">
        <template slot="prepend">Факт</template>
      </el-input>
    </el-form-item>
    <el-form-item
      label="% выплаты по цели"
    >
      <el-input type="number" v-model="scale.bonus" :disabled="disabled || scale.auto_bonus">
        <template slot="prepend">% выплаты по цели</template>
      </el-input>
    </el-form-item>
  </el-form>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import {mapGetters} from "vuex";

export default {
  name: "library-edit-form",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {},

  computed: {
    ...mapGetters('settings', {
      settings: 'settings',
    }),
  },

  watch: {
    // 'scale.fixed_pay': function (newVal){
    //   if( newVal ){
    //     if( !this.scale.target_b ){
    //       this.scale.target_a = null;
    //       this.scale.target_c = null;
    //     } else {
    //       this.scale.target_a = (this.settings.scale_fixed_pay_target_a * this.scale.target_b / 100).toFixed(2) ;
    //       this.scale.target_c = (this.settings.scale_fixed_pay_target_c * this.scale.target_b / 100).toFixed(2) ;
    //     }
    //     this.scale.pay_a = this.settings.scale_fixed_pay_pay_a;
    //     this.scale.pay_b = this.settings.scale_fixed_pay_pay_b;
    //     this.scale.pay_c = this.settings.scale_fixed_pay_pay_c;
    //   } else {
    //     this.scale.target_a = null;
    //     this.scale.target_c = null;
    //     this.scale.pay_a = null;
    //     this.scale.pay_b = null;
    //     this.scale.pay_c = null;
    //   }
    // },
    'scale.target_b': function (newVal){
      if( this.scale.fixed_pay ){
        if( !newVal ){
          this.scale.target_a = null;
          this.scale.target_c = null;
        } else {
          this.scale.target_a = (this.settings.scale_fixed_pay_target_a * this.scale.target_b / 100).toFixed(2) ;
          this.scale.target_c = (this.settings.scale_fixed_pay_target_c * this.scale.target_b / 100).toFixed(2) ;
        }
      }
    }
  },

  mounted() {
    if( !this.scale.pay_b ){
      this.scale.pay_b = 100;
    }
    this.$nextTick(function () {
      this.$refs.editForm.clearValidate();
    })
  },

  data() {
    const validatePayDirection = (rule, value, callback) => {
      if (rule.field === 'pay_a') {
        this.$refs.editForm.validateField('pay_c');
        callback();
      }
      if (rule.field === 'pay_b') {
        this.$refs.editForm.validateField('pay_c');
        callback();
      }
      if (rule.field === 'pay_c') {
        if (+this.scale.pay_c < +this.scale.pay_b) {
          callback(new Error('Max не может быть меньше Target'));
        }
        if (+this.scale.pay_b > +this.scale.pay_c) {
          callback(new Error('Target не может быть больше Max'));
        }
        if (+this.scale.pay_b < +this.scale.pay_a) {
          callback(new Error('Target не может быть меньше Min'));
        }
        if (+this.scale.pay_a > +this.scale.pay_b) {
          callback(new Error('Min не может быть больше Target'));
        }
        callback();
      }
    };
    const validateEqualValues = (rule, value, callback) => {
      if( rule.field === 'target_b' ){
        if( this.scale.target_a == this.scale.target_b && this.scale.pay_a != this.scale.pay_b){
          callback(new Error('При одинаковых целевых должны быть одинаковые выплаты'));
        }
        if( this.scale.target_c == this.scale.target_b && this.scale.pay_c != this.scale.pay_b){
          callback(new Error('При одинаковых целевых должны быть одинаковые выплаты'));
        }
        callback();
      } else {
        this.$refs.editForm.validateField('target_b');
        callback();
      }
    };
    const validateValue = (rule, value, callback) => {
      if( value < rule.min || value > rule.max ){
        callback(new Error('Минимум '+rule.min+', максимум '+rule.max+''));
      }
      callback();
    };

    return {
      scale: {
        fixed_pay: false,
        auto_bonus: true,
        unit: null,

        target_a: null,
        target_b: null,
        target_c: null,
        pay_a: null,
        pay_b: null,
        pay_c: null,
        fact: null,
        bonus: null,
      },

      rules: {
        pay_a: [
          { validator: validatePayDirection, trigger: 'change'},
          { validator: validateEqualValues, trigger: 'change'},
          { validator: validateValue, min: 0, max: 120, trigger: 'blur' },
        ],
        pay_b: [
          { validator: validatePayDirection, trigger: 'change'},
          { validator: validateEqualValues, trigger: 'change'},
          { validator: validateValue, min: 0, max: 120, trigger: 'blur' },
        ],
        pay_c: [
          { validator: validatePayDirection, trigger: 'change'},
          { validator: validateEqualValues, trigger: 'change'},
          { validator: validateValue, min: 0, max: 120, trigger: 'blur' },
        ],

        // target_a: [{ validator: validateEqualValues, trigger: 'change'}],
        // target_b: [{ validator: validateEqualValues, trigger: 'change'}],
        // target_c: [{ validator: validateEqualValues, trigger: 'change'}],
      }


    }
  },

  methods: {}
}
</script>

<style>

</style>